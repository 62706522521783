<template>
<div class="programme common-block">
  <div class="common-title-n">
    <div class="common-title-l">
      <img src="@/images/jobOverview_icon.png"/>
      <div>{{$t('工作概览')}}</div>
    </div>
    <div class="common-title-r">
      <div class="time">
        <div class="week" :class="{ active: dataText == 'today' }" @click="onDateShortcut('today')">
          {{$t('今日')}}
        </div>
        <div class="week" :class="{ active: dataText == 'week' }" @click="onDateShortcut('week')">
          {{$t('本周')}}
        </div>
        <div :class="{ active: dataText == 'month' }" class="month" @click="onDateShortcut('month')">
          {{$t('本月')}}
        </div>
      </div>
    </div>
  </div>
  <div class="options">
    <div v-if="showStaffGroup" class="role" @click="popupShow = true">
      <span>{{$t('员工')}}</span>
      <span>：</span>
      <span v-if="!empData.length">{{$t('全部')}}</span>
      <van-tag v-else closeable @close="deleteEmp">
        {{ empData[empData.length - 1].name }}
      </van-tag>
      <van-tag v-show="empData.length > 1" style="margin-left: 5px">
        {{ `+${empData.length - 1}` }}
      </van-tag>
      <van-icon class="play" name="play" />
    </div>
    <div v-if="current == 1" class="channel" @click="actionSheetShow = true">
      <span>{{$t('渠道')}}：{{ actions[sheetIndex].name }}</span>
      <van-icon class="play" name="play" />
    </div>
  </div>
  <van-swipe indicator-color="#0040C5" @change="(index) =>{ current = index}">
    <van-swipe-item>
      <div class="overView">
        <div class="overView-l" @click="gotoPath('LEAD_TARGET',leadTaegetShow)">
          <div class="label">{{$t('有效线索量')}}</div>
          <div class="count">{{ leadTaegetShow }}</div>
        </div>
        <div class="overView-r">
          <div v-for="(lead,idx) in leadChannels" :key="lead.code" :class="['overView-r-i',idx!==0?'grap':'']" @click="gotoPath('channel',lead)">
            <div class="label">{{ lead.name }}</div>
            <div class="count">{{ `${lead.finished}` }}{{ lead.target? '/':'' }}{{ lead.target }}</div>
          </div>
        </div>
      </div>
      <div class="subdivision">
        <div v-for="(item, index) in targetStats" :key="index" class="subdivision-i" @click="gotoPath(item.code,item)">
          <div class="title">{{ item.name }}</div>
          <div v-if="['PASSENGER_TARGET', 'FOLLOW_TARGET'].includes(item.code)" class="count">{{ item.finished }}</div>
          <div v-else class="count">{{ item.target === undefined ? item.finished : `${item.finished}/${item.target || 0}` }}</div>
        </div>
      </div>
    </van-swipe-item>
    <van-swipe-item v-if="formData.roleCode !== ROLES.DCC">
      <div style="min-height: 215px">
        <Charts v-if="userInfo.id" ref="charts" :from="'sales'" :formData="formData" :empData="empData"/>
      </div>
    </van-swipe-item>
  </van-swipe>
  <!-- 员工 -->
  <van-popup
    v-model="popupShow"
    position="bottom"
    get-container="body"
    class="safe-area-inset-bottom"
    :style="{
      height: 'auto',
      'max-height': '75%',
      'min-height': '50%',
      'border-radius': '16px 16px 0 0',
      left: '50%',
      transform: 'translateX(-50%)',
      'max-width': '500px',
    }"
    @close="onClosePopup">
    <div class="popup-title">
      <p @click="popupShow = false">{{$t('确认')}}</p>
    </div>
    <Select
      v-for="item in items"
      :id="item.id"
      :key="item.id"
      :ref="`select_${item.id}`"
      :list="item.buddys"
      :name="item.name">
    </Select>
  </van-popup>
  <!-- 渠道 -->
  <van-action-sheet
    v-model="actionSheetShow"
    :style="{
      left: '50%',
      transform: 'translateX(-50%)',
      'max-width': '500px',
    }"
    :actions="actions"
    :cancel-text="$t('取消')"
    close-on-click-action
    @select="onChannelSelect"
  />
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { listSalesGroup } from '@/services/goalProgress'
import baseDataServices from '@/services/baseDataServices'
import Select from '../../goal-progress/components/Select.vue'
import { ROLES } from '@/constants/constants'
import salesAssistantService from '@/services/salesAssistant'
import dayjs from 'dayjs'
import loading from '@/utils/loading'
import Charts from '../../goal-progress/components/Charts.vue'
import { dateFormat } from '@/utils'
export default {
  components: {
    Select,
    Charts
  },
  props: {
    rolesInfo: {
      type: [Object, String],
      default: {}
    }
  },
  data() {
    return {
      ROLES,
      current: 0,
      sheetIndex: 0,
      popupShow: false,
      actionSheetShow: false,
      actions: [],
      empData: [],
      items: [],
      dataText: 'today',
      leadChannels: [],
      targetStats: [],
      targetStatsAll: [],
      formData: {
        channelOneId: '',
        dealerId: '',
        empIds: [],
        roleCode: '',
        statisticsTime: {
          start: dayjs().format('YYYY-MM-DD 00:00:00'),
          end: dayjs().format('YYYY-MM-DD 23:59:59'),
        },
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    roleLogos() {
      return this.userInfo.roleLogos || []
    },
    // 是否展示员工分组数据 当前选择的角色魏店长 或者组长
    showStaffGroup() {
      return this.rolesInfo && [ROLES.SHOWOWNER,ROLES.MASTER].includes(this.rolesInfo.roleCode)
    },
    // 线索量展示
    leadTaegetShow() {
      const obj = this.targetStatsAll.find(({ code }) => { return code === 'LEAD_TARGET' })
      return obj ? (obj.target === undefined || obj.target === '' ? obj.finished : `${obj.finished}/${obj.target}`) : ''
    },
  },
  watch: {
    rolesInfo: {
      async handler(val) {
        if (val && val.roleCode) {
          this.formData.dealerId = val.dealerId
          this.formData.roleCode = val.roleCode
          if (['1014003', '1014004'].includes(val.roleCode)) {
            const list = await listSalesGroup({
              dealerId: val.dealerId || '',
              roleCode: val.roleCode
            })
            this.items = list
          }
          this.getOverView()
          this.$nextTick(() => {
            this.queryTranfer()
          })
        }
      },
      immediate: true
    },
  },
  created() {
    this.getChannels()
  },
  methods: {
    // 获取渠道数据
    async getChannels() {
      const result = await baseDataServices.getChannelListById({ channelId: '' }).catch(err => console.log(err))
      if (result && Array.isArray(result)) {
        this.actions = result.map((item) => {
          return {
            value: item.id,
            name: item.name
          }
        })
      }
      this.actions.unshift({ value: '', name: this.$t('全部') })
    },
    // 计算当前周
    getCurrentWeek() {
      const start = this.$moment()
        .isoWeekday(1)
        .format('YYYY-MM-DD 00:00:00') // 本周一
      const end = this.$moment()
        .isoWeekday(7)
        .format('YYYY-MM-DD 23:59:59') // 本周日
      this.formData.statisticsTime = {
        start,
        end,
      }
    },
    // 按日期查询
    onDateShortcut(type, load = true) {
      this.dataText = type
      if (type == 'today') {
        this.formData.statisticsTime = {
          start: dayjs().format('YYYY-MM-DD 00:00:00'),
          end: dayjs().format('YYYY-MM-DD 23:59:59'),
        }
      } else if (type == 'week') {
        this.getCurrentWeek()
      } else {
        const start = this.$moment()
          .startOf(type)
          .format('YYYY-MM-DD 00:00:00')
        const end = this.$moment()
          .endOf(type)
          .format('YYYY-MM-DD 23:59:59')
        this.formData.statisticsTime = {
          start,
          end,
        }
      }
      load && this.getOverView()
      load && this.queryTranfer()
    },
    // 获取概览数据
    async getOverView() {
      loading.showLoading()
      const result = await salesAssistantService.workOverview(this.formData)
      loading.hideLoading()
      this.targetStats = []
      this.targetStatsAll = []
      const { leadChannels=[],targetStats=[] } = result
      this.leadChannels = leadChannels || []
      this.targetStatsAll = targetStats || []
      this.targetStats = this.targetStatsAll.filter(({ code }) => { return code != 'LEAD_TARGET' })
    },
    // 删除选择员工
    deleteEmp() {
      const { pId, id } = this.empData[this.empData.length - 1]
      const result = this.$refs[`select_${pId}`][0].result
      const index = result.findIndex((e) => id == e)
      this.empData.pop()
      this.$refs[`select_${pId}`][0].result.splice(index, 1)
      this.formData.empIds = this.empData.map(({ id }) => id)
      this.getOverView()
      this.queryTranfer()
    },
    // 关闭员工选择弹窗
    onClosePopup() {
      const arr = []
      const arr2 = []
      this.items.forEach(({ id }) => {
        const result = this.$refs[`select_${id}`][0].result
        result.length &&
          arr.push(
            ...result.map((e) => {
              const [pId, id, name] = e.split('_')
              arr2.push({ pId, id, name })
              return id
            })
          )
      })
      this.empData = arr2
      this.formData.empIds = this.empData.map(({ id }) => id)
      this.getOverView()
      this.queryTranfer()
    },
    queryTranfer() {
      this.formData.channelOneId = this.actions[this.sheetIndex]?.value
      this.$refs.charts && this.$refs.charts.initTranfer(this.formData)
    },
    // 选择渠道
    onChannelSelect(action, index) {
      this.sheetIndex = index
      this.queryTranfer()
    },
    // 跳转
    gotoPath(type,item){
      const { start,end } = this.formData.statisticsTime
      if (['LEAD_TARGET','channel'].includes(type)){
        // 跳转线索明细
        this.$router.push({
          path: '/clue-list',
          query: {
            validTimeStart: start,
            validTimeEnd: end,
            followUserIds:this.empData.map(item=>item.id),
            channelOneId: type==='channel' ? item.code: '',
            roleCode: this.rolesInfo.roleCode,
            validStatus: 1
          }
        })
        return
      }
      if (['APPOINTMENT_TARGET','DRIVE_TARGET'].includes(type)){
        // 跳转预约
        this.$router.push({
          path: '/schedulingDrive',
          query: {
            followUserIds: this.empData.map(item=>item.id).join(','),
            type,
            roleCode: this.rolesInfo.roleCode,
            start,
            end
          },
        })
        return
      }
      if (['ORDER_TARGET','DELIVERY_TARGET'].includes(type)){
        const query = {
          followUserIds: this.empData.map(item=>item.id),
          roleCode: this.rolesInfo.roleCode
        }
        if (type==='ORDER_TARGET'){
          // query.orderCreatedTimeStart = start
          // query.orderCreatedTimeEnd = end
          // query.contractTimeStart = start
          // query.contractTimeEnd = end
          query.lockTimeStart = start
          query.lockTimeEnd = end
        }
        if (type==='DELIVERY_TARGET'){
          query.deliveryTimeStart = start
          query.deliveryTimeEnd = end
          query.orderStatuses = 'ORDER_COMPLETED'
        }
        // 跳转订单
        this.$router.push({
          path: '/order-list',
          query
        })
        return
      }
      if (type === 'FOLLOW_TARGET') {
        this.$router.push({
          path: '/taskManage/follow',
          query: {
            taskStatus: '1025002',
            execTimeStart: start,
            execTimeEnd: end,
            roleCode: this.rolesInfo.roleCode,
            executorIds:this.empData.map(item=>item.id)
          }
        })
        return
      }
    }
  },
}
</script>
<style lang="less" scoped>
@import "./common.less";
.programme{
  overflow: hidden;
  font-size: 13px;
  color: @black;
  padding: 12px;
  /deep/ .van-swipe-item{
    width: 100%;
    background: #ffffff;
    height: auto;
    padding: 6px;
    &:first-of-type{
      flex-direction: column;
    }
  }
  /deep/ .van-swipe__indicator{
    background: #E7E7E7;
  }
  .options{
    display: flex;
    justify-content: flex-end;
  }
  .role {
    display: flex;
    align-items: center;
    margin-top: 12px;
    justify-content: flex-end;
    height: 24px;
    cursor: pointer;
    .play {
      margin-top: -2px;
      margin-left: 6px;
      transform: rotate(90deg);
    }
  }
  .channel{
    display: flex;
    align-items: center;
    margin-top: 12px;
    justify-content: flex-end;
    height: 24px;
    margin-left: 12px;
    cursor: pointer;
    .play {
      margin-top: -2px;
      margin-left: 6px;
      transform: rotate(90deg);
    }
  }
  .overView{
    display: flex;
    flex-direction: column;
    min-height: 52px;
    height: auto;
    align-items: center;
    padding:0 8px 8px 8px;
    margin: 12px 0;
    background: rgba(0,64,197,0.03);
    border-radius: 4px;
    .overView-l{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 37px;
      font-size: 13px;
      width: 100%;
      border-bottom: 1px solid rgba(13,23,26,0.05);
      margin-bottom: 8px;
      .label{
        margin-right: 8px;
      }
      .count{
        font-size: 13px;
      }
    }
    .overView-r{
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      height: 36px;
      .overView-r-i{
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        font-size: 12px;
        &:first-of-type {
          padding-right: 4px;
        }
        .label{
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // color: rgba(13,23,26,0.45);
          color: @black;
          font-size: 12px;
          text-align: center;
          height: 20px;
          line-height: 20px;
        }
        .count{
          color: @black;
          text-align: center;
        }
      }
      .grap{
        position: relative;
        padding:0 4px;
        &::before{
          position: absolute;
          width: 1px;
          height: 20px;
          top: 50%;
          left: 0px;
          transform: translateY(-50%);
          background: rgba(13,23,26,0.05);
          content: '';
        }
      }
    }
  }
  .subdivision{
    display: grid;
    min-height: 96px;
    height: auto;
    box-sizing: border-box;
    grid-row-gap: 8px;
    padding: 8px;
    grid-template-columns: repeat(3, calc(100%  / 3));
    grid-template-rows: repeat(auto-fit);
    font-size: 12px;
    background: rgba(0,64,197,0.03);
    .subdivision-i{
      min-height: 36px;
      padding: 0 4px;
      height: auto;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      justify-content: space-around;
      box-sizing: border-box;
      align-items: center;
      position: relative;
      &:nth-of-type(1){
        &::after {
          position: absolute;
          width: 1px;
          height: 20px;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          background: rgba(13,23,26,0.05);
          content: '';
        }
      }
      &:nth-of-type(2){
        &::after {
          position: absolute;
          width: 1px;
          height: 20px;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          background: rgba(13,23,26,0.05);
          content: '';
        }
      }
      &:nth-of-type(3){
          &::before {
          content: ' ';
          position: absolute;
          bottom: -4px;
          width: 64px;
          height: 1px;
          background: #EAEFF3;
        }
      }
      &:nth-of-type(4){
          &::before {
          content: ' ';
          position: absolute;
          top: -5px;
          width: 64px;
          height: 1px;
          background: #EAEFF3;
        }
      }
      &:nth-of-type(4){
        &::after {
          position: absolute;
          width: 1px;
          height: 20px;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          background: rgba(13,23,26,0.05);
          content: '';
        }
      }
      &:nth-of-type(5){
          &::before {
          content: ' ';
          position: absolute;
          top: -5px;
          width: 64px;
          height: 1px;
          background: #EAEFF3;
        }
      }
      &:nth-of-type(5){
        &::after {
          position: absolute;
          width: 1px;
          height: 20px;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          background: rgba(13,23,26,0.05);
          content: '';
        }
      }
      .title{
        height: auto;
        color: @black;
        font-size: 13px;
      }
      .count{
        height: 22px;
        font-size: 12px;
        color: @black;
        font-size: 13px;
      }
    }
  }
}
.popup-title {
  position: sticky;
  display: flex;
  justify-content: flex-end;
  left: 0;
  top: 0;
  z-index: 1;
  background: white;
  p {
    line-height: 36px;
    height: 44px;
    padding: 4px 16px;
    cursor: pointer;
  }
}
</style>